import React, {useEffect, useReducer,useState} from "react";
import ClientDetails from "./ClientDetails";
import "./ClientProfile.css";
import {Link, useParams, withRouter} from "react-router-dom";
import ClientCloseOut from './ClientCloseOut'
import ClientCheckInHistory from "./ClientCheckInHistory"
import ClientInventoryHistory from "./ClientInventoryHistory"
import { Row, Col, Tab, Tabs, Button} from 'react-bootstrap'
import BlueBin from "../../pages/BlueBin"
import { Endpoint } from '../../api/base'
import { useOptionsState } from '../../helpers/optionsContext'
import { IntakeHistory }  from "./IntakeHistory"
import EnrolPopUp from "./EnrolPopUp";
import { formatToLocalAddDay } from "../../helpers/dateHelpers";
import Swal from "sweetalert2";
import {ConfirmModal} from "../WaitList/ConfirmModal";
import ClientGrayBinNotes from "./ClientGrayBinNotes";
import GrayBin from "../../pages/GrayBin";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUndo} from "@fortawesome/free-solid-svg-icons";
const initialState = {
  setModalShow: false,
  profilePicture: undefined,
  loading: false,
  showConfirm: false,
  userStatus: 1
};

function reducer (state, action)  {
  switch(action.type) {
    case 'setModalShow':
      return {...state, modalShow: action.payload}
    case 'setProfilePicture':
      return {...state, profilePicture: action.payload}
    case 'setLoading':
      return {...state, loading: action.payload}
    case 'setShowConfirm':
      return {...state, showConfirm: action.payload}
    case 'setUserStatus':
      return {...state, userStatus: action.payload}
    default:
      throw new Error("Something went wrong!")
  }
}

function ClientProfile(props) {
  const [showUndo, setShowUndo] = useState()
  const { AccountStatus, CheckInType, HistoryAction } = useOptionsState()
  const [state, dispatch] = useReducer(reducer, initialState, undefined)
  let { id, tabKey } = useParams();

  const onSelectTabs = (tab) => {
    props.history.push(`/app/clients/${id}/${tab}`)
  }
  useEffect(() =>{
    const undoStatus = localStorage.getItem("showUndo")
    if (undoStatus !== undefined) {
      setShowUndo(undoStatus)
    }
    return () => {
      const undoStatus = localStorage.getItem("showUndo")
    if (undoStatus !== undefined) {
      setShowUndo(false)
      localStorage.setItem("showUndo",false)
    }
    }
  },[])
  useEffect(() => {
    Endpoint.Clients.getImage(id).then(res => {
      dispatch({ type: 'setProfilePicture', payload: URL.createObjectURL(res.data)});
    }).catch(e => {
    })
  }, [id])

  const openChangeUserStatus = (status) => {
    dispatch({ type: 'setUserStatus', payload: status });
    dispatch({ type: 'setShowConfirm', payload: true });
  }

  const handleChangeUserStatus = () => {
    if(state.userStatus === 1)
      retrievalCloseOut();
    else
      disposalCloseOut();
  }
  const undoOperation = async () => {
        
    Endpoint.Clients.CloseOut.undoOperation(id).then(res => {
             
             localStorage.setItem("showUndo",false)
             window.location.reload();
        

     }).catch(err => {
         Swal.fire('something went wrong', err.response ? err.response.data : err.message, 'error')
         console.error(err)
       });
     
 }

  const retrievalCloseOut = () => {
    dispatch({ type: 'setLoading', payload: true });
    Endpoint.Clients.retrieval(id).then((response) => {
      window.location.reload();
    }).catch((err) => {
      dispatch({ type: 'setLoading', payload: false })
      console.error(err)
      Swal.fire('something went wrong', err.response ? err.response.data : err.message, 'error')
    });
  }

  const disposalCloseOut = () => {
    dispatch({ type: 'setLoading', payload: true });
    Endpoint.Clients.disposal(id).then((response) => {
      window.location.reload();
    }).catch((err) => {
      dispatch({ type: 'setLoading', payload: false })
      console.error(err)
      Swal.fire('something went wrong', err.response ? err.response.data : err.message, 'error')
    });
  }

  return (
      <Row className="m-0">
        <Col xs={12} md={3} className="py-3" >
          <div className="left">

            <div className="user-info">
              {/*<img src={`${baseURL}client/DownloadImage/${props.data?.id}?t=${new Date().getTime()}`} alt="Profile" width="100" />*/}
              <img src={state.profilePicture} alt="Profile" width="100"/>
              <h4>{props.data.firstName + " " + props.data.lastName}</h4>
              <p>Bin Number : <span className="font-weight-bold">{props.data?.bin?.binNumber}</span></p>
              <p>Clarity Id : <span className="font-weight-bold">{props.data.clarityId}</span></p>
              <p>User Status: <span className={`font-weight-bold ${props?.data?.accountStatusId === 1 && "text-success"}`}>
                { props.data.accountStatusId ?
                    props.data.accountStatusId === 2 ?
                        AccountStatus[props.data.accountStatusId].replace(/_/g, " ") + " " + formatToLocalAddDay(props?.data?.checkIn.slice(-1)?.[0]?.checkInTime+"Z", 60)
                        :
                        AccountStatus[props.data.accountStatusId].replace(/_/g, " ")
                    :
                    "-"
                } </span>
              </p>
            </div>
            <EnrolPopUp show={state.modalShow} onHide={() => dispatch({ type: 'setModalShow', payload: false })} data={props.data}/>
            <ConfirmModal
                loading={state.loading}
                text={
                  state.userStatus === 1 ? "Are you sure you want to Retrieval Close Out?" :
                      "Are you sure you want to Disposal Close Out?"
                }
                submitText={
                  state.userStatus === 1 ? "Retrieval Close Out" :
                      "Disposal Close Out"
                }
                submitVariant="outline-danger"
                isOpen={state.showConfirm} handleClose={() => dispatch({ type: 'setShowConfirm', payload: false })}
                onConfirm={handleChangeUserStatus}
            />
            <div className="profile-actions py-2 py-lg-4">
              {props.data.accountStatusId === 1?
                  <div style={{ marginBottom: '10px' }}>
                    <div className="action">
                      <ClientCloseOut className="w-100 mb-3" binId={props.data?.bin?.binNumber} clientId={props.data.id} inventory={props.data.inventory}/>
                    </div>
                   
                    <div className="action">
                      <Link to={`/app/check-in-form/${props.data.id}`} >
                        <Button variant="outline-success" size="sm">Check in</Button>
                      </Link>
                    </div>
                  </div>
                  :
                  <div className="action">

<div className="action">
                   {showUndo === true || showUndo === "true" ? (
                <Button
                    size="sm"
                    variant="dark"
                    onClick={() => undoOperation()}
                >
                  <FontAwesomeIcon size="lg" icon={faUndo}/> Undo Closeout
                </Button>
            ) : ""}  
                  </div>
                  
                  <div className="action">
                    

                    <Button variant="primary" size="sm" onClick={() => dispatch({ type: 'setModalShow', payload: true })}>
                      Re-Enroll
                    </Button>
                  </div>

                  </div>
                  
                  
                  }
              <div className="action">
                <Link to={`/app/edit-client/${props.data.id}`}>
                  <Button variant="outline-dark" size="sm" >Edit Profile</Button>
                </Link>
              </div>
              { props.data.accountStatusId && props.data.accountStatusId === 2 ?
                  [
                    <div className="action">
                      <Button variant="outline-danger" size="sm" onClick={() => openChangeUserStatus(1)}>Retrieval Close Out</Button>
                    </div>,
                    <div className="action">
                      <Button variant="outline-danger" size="sm" onClick={() => openChangeUserStatus(2)}>Disposal Close Out</Button>
                    </div>
                  ] : ""
              }
            </div>
          </div>
        </Col>
        <Col xs={12} md={9} className="py-3">
          <div className="right py-2 h-100">
          <Tabs
              className="client-profile-tabs"
              defaultActiveKey="profile"
              id="uncontrolled-tab-example"
              activeKey={tabKey}
              onSelect={(k) => onSelectTabs(k)}
          >
            <Tab  eventKey="profile" title="Profile">
              <div className="client_profile__tab_container">
              <ClientDetails data={props?.data}/>
              </div>
            </Tab>
            <Tab eventKey="history" title="Check In History">
              <div className="client_profile__tab_container">
                <ClientCheckInHistory history={props.history} clientId={props.data.id} data={props?.data?.checkIn} types={CheckInType} />
              </div>
            </Tab>
            <Tab eventKey="IntakeHistory" title="Intake History">
              <div className="client_profile__tab_container">
                <IntakeHistory data={props.data}/>
              </div>
            </Tab>
            <Tab eventKey="blueBin" title="Blue Bin">
              <div className="client_profile__tab_container">
                <BlueBin getInventoryHistory={props?.getInventoryHistory} inventory={props?.data?.inventory} clientId={props?.data?.id}/>
              </div>
            </Tab>
            <Tab eventKey="grayBin" title="Gray Bin">
              <div className="client_profile__tab_container">
                <GrayBin getInventoryHistory={props?.getInventoryHistory} inventory={props?.data?.grayBin} clientId={props?.data?.id}/>
              </div>
            </Tab>
            <Tab eventKey="inventoryHistory" title="Inventory History">
              <div className="client_profile__tab_container">
                <ClientInventoryHistory clientId={props.data.id} data={props?.inventoryHistory} types={HistoryAction} />
              </div>
            </Tab>
            <Tab eventKey="GrayBinNotes" title="Notes">
              <div className="client_profile__tab_container">
                <ClientGrayBinNotes clientId={props.data.id}/>
              </div>
            </Tab>
          </Tabs>
          </div>
        </Col>
      </Row>
  );
}

export default withRouter(ClientProfile);
